@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Thin.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 200;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-ExtraLight.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Light.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-SemiBold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Bold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-ExtraBold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/Pretendard-Black.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Light.woff2')
    format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Regular.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Medium.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Bold.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}

/* reset-css */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-size: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

* {
  font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
}
/* global style */

/* antd */
.ant-drawer .ant-drawer-content {
  border-radius: 12px 12px 0px 0px !important;
}
.ant-drawer .ant-drawer-body {
  padding: 0px !important;
}
